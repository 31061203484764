import {useState, useEffect} from "react";
import Fab from "@mui/material/Fab";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Switch from "@mui/material/Switch";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";

// import Slide, { SlideProps } from '@mui/material/Slide'
import DialogContentText from "@mui/material/DialogContentText";
// Form

import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";

import SystemSettings from "src/service/SystemSettings";
import InfoService from "src/service/Info";

import toast from "react-hot-toast";

// ** Icon Imports
import Icon from "src/@core/components/icon";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

// Custom Component
import BlockLoader from "src/components/BlockLoader";

// Socket
import {socket} from "src/socket";

import ExchangeApiSettingService from "src/service/ExchangeApiSetting";


const FiatPriceSource = () => {
    const [source, setSource] = useState(null);


    useEffect(() => {
        SystemSettings.get().then((r) => {
            setSource(r?.data?.fiatPriceSource)
        })
    }, [])


    const setSourceHandler = (source) => {
        SystemSettings.setPriceSource({source}).then((r) => {
            setSource(source)
            let sourceName = source == 'api' ? 'API' : 'Вручную';
            toast.success(`Источник изменен на: ${sourceName}`)
        })
    }
    // let [list, setList] = useState([]);

    //
    // useEffect(() => {
    //     ExchangeApiSettingService.getExchanges().then((r) => setList(r.data));
    //
    //     socket.on("exc_api_settings_add", (e) => {
    //         setList((list) => [e, ...list]);
    //     });
    //     socket.on("exc_api_settings_delete", (id) => {
    //         setList((list) => list.filter((i) => i._id !== id));
    //     });
    //
    //     return () => {
    //         socket.off("exc_api_settings_add");
    //         socket.off("exc_api_settings_delete");
    //     };
    // }, []);
    //
    // const toggleDialog = () => {
    //     setShowDialog(!showDialog);
    // };
    //
    // const handleToggleExchange = (id, val) => {
    //     ExchangeApiSettingService.toggleExchange({id, enabled: val}).then(() => {
    //         toast.success(`Биржа ${val ? "включена" : "отключена"}!`);
    //         setList((list) =>
    //             list.map((i) => (i._id === id ? {...i, enabled: val} : i))
    //         );
    //     });
    // };
    //
    // const handleDeleteExchange = (id) => {
    //     ExchangeApiSettingService.deleteExchange(id).then(() => {
    //         toast.success(`Биржа удалена!`);
    //     });
    // };

    const content = () => {
        return <CardContent>
            <RadioGroup
                column
                value={source}
                onChange={(e) => setSourceHandler(e.target.value)}
            >
                <FormControlLabel
                    control={<Radio size="small"/>}
                    label="API"
                    value="api"
                />
                <FormControlLabel
                    control={<Radio size="small"/>}
                    label="Вручную"
                    value="manual"
                />
            </RadioGroup>
            {
                source == 'manual' ? <ManualPriceSource/> : null
            }

        </CardContent>
    }

    return (
        <Card>

            <CardHeader
                title={"Источник цен на Фиат"}

            />
            {
                source ? content() : null
            }

        </Card>
    );
};

function ManualPriceSource() {
    const [prices, setPrices] = useState([]);
    const [isSave, setIsSave] = useState(false);

    useEffect(() => {
        InfoService.getFiatPrice().then((r) => {
            let data = r.data;
            setPrices(dataPriceToArray(data))
        });

    }, [])

    const saveHandler = () => {
        InfoService.setManualFiatPrice(prices).then(() => {
            toast.success('Цены сохранен!')
        })
    }


    if (prices.length == 0) return null;

    return <div><List>
        {prices.map((cur) => {
            return <ListItem
                key={cur.code}>
                <ListItemText primary={cur.code}/>
                <TextField
                    type="number"
                    value={cur.price}
                    size='small'
                    fullWidth

                    sx={{
                        marginLeft: 4,

                    }}
                    onChange={(e) => {
                        let newPrices = prices.map((p) => {
                            if (p.code == cur.code) {
                                return {
                                    ...p,
                                    price: e.target.value
                                }
                            }
                            return p;
                        })
                        setPrices(newPrices)
                    }}
                />
            </ListItem>
        })}
    </List>
        <Button variant='contained'
                disabled={isSave}
                onClick={saveHandler}
                startIcon={isSave ? <CircularProgress size="1rem" /> : null}
        >
            Сохранить
        </Button>
    </div>

}

function dataPriceToArray(data) {
    return [
        {
            code: 'USD',
            price: data['USD']
        },
        {
            code: 'EUR',
            price: data['EUR']
        },
        {
            code: 'BYN',
            price: data['BYN']
        },
        {
            code: 'KZT',
            price: data['KZT']
        },
        {
            code: 'TRY',
            price: data['TRY']
        },
        {
            code: 'UAH',
            price: data['UAH']
        }
    ]
}

export default FiatPriceSource;
