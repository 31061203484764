import axios from "axios";

class SystemSettings {
  get() {
    return axios.get("/system-setting");
  }
  change(data) {
    return axios.put("/system-setting", data);
  }
  setPriceSource(data) {
    return axios.patch("/system-setting/fiat-price-source", data);
  }
}

export default new SystemSettings();
