// ** MUI Imports
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

// Modules
import ConnectedExchanges from "./ConnectedExchanges";
import CurrencyPool from "src/modules/CurrencyPool/CurrencyPool";
import CurrencyConnectedExchanges from "./CurrencyConnectedExchanges";
import SiteCurrencies from "./SiteCurrencies/SiteCurrencies";
import SiteCourses from "./SiteCourses/SiteCourses";
import CryptoPriceShift from "./CryptoPriceShift";
import RairCoins from "./RairCoins/RairCoins";
import CryptoCurrencyNets from "./CryptoCurrencyNets";
import FiatPriceSource from "./FiatPriceSource";

import UnificationNets from "./UnificationNets";

const CurrencySettings = () => {
    return (
        <Grid className="match-height" container spacing={6}>
            <Grid item xs={12} md={6}>
                <FiatPriceSource/>
            </Grid>
            <Grid item xs={12} md={6}>
                <ConnectedExchanges/>
            </Grid>
            <Grid item xs={12} md={6}>
                <CurrencyConnectedExchanges/>
            </Grid>
            <Grid item xs={12} md={6}>
                <RairCoins/>
            </Grid>
            <Grid item xs={12} md={6}>
                <CryptoCurrencyNets/>
            </Grid>
            <Grid item xs={12} md={6}>
                <CurrencyPool withTransition/>
            </Grid>
            {/* <Grid item xs={12} md={6}>
        <UnificationNets />
      </Grid> */}
            <Grid item xs={12} md={6}>
                <CryptoPriceShift/>
            </Grid>
            <Grid item xs={12} md={6}>
                <SiteCurrencies/>
            </Grid>
            <Grid item xs={12} md={12}>
                <SiteCourses/>
            </Grid>
        </Grid>
    );
};

export default CurrencySettings;
