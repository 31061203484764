import axios from "axios";

class Info {
  getBtcRate() {
    return axios.get("/info/btc-rate");
  }
  getFiatPrice() {
    return axios.get("/info/fiat-price");
  }
  setManualFiatPrice(data) {
    return axios.patch("/info/manual-fiat-price", data);
  }
  getNotifyData() {
    return axios.get("/info/notification");
  }
}

export default new Info();
